.privacyPart {
  overflow: hidden;
  margin-left: 50px;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 60px;
  padding-bottom: 100px;
  border-left: 1px solid #5c5c60;
  border-right: 1px solid #5c5c60;
  box-sizing: border-box;
}
.privacyTitle {
  display: inline-block;
  width: 230px;
  height: 30px;
  background-image: url("../../assets/images/PRIVACY-NOTICE.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}
.privacyPart > p {
  color: #ddd;
  line-height: 1.5;
}
.privacyPart > p > a {
  color: #ddd;
}
.privacyPart > p > a:hover {
  color: #777;
}

.privacyCloseBtn {
  position: absolute;
  top: 0;
  right: 60px;
  font-style: unset;
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.privacyCloseBtn:hover {
  color: unset;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
  .privacyPart > p {
    line-height: 1.3;
  }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .privacyPart {
    margin-left: unset;
    border-left: unset;
    border-right: unset;
    padding-top: 30px;
    padding-right: 20px;
  }
  .privacyTitle {
    width: 100%;
    background-position: center center;
  }
  .privacyCloseBtn {
    right: 15px;
  }
}
