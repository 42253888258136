.contentBox {
  position: relative;
  max-width: 940px;
  margin: 0 auto;
  border-left: 1px solid #5c5c60;
  border-right: 1px solid #5c5c60;
  box-sizing: border-box;
}

/* sign up part */
.signupPart {
  height: 488px;
  padding-top: 60px;
  box-sizing: border-box;
}
.signupContent {
  position: relative;
  width: 48%;
}
.signupContent > h2 {
  margin: 0 0 8%;
}

.fullShapeSquare {
  display: inline-block;
  vertical-align: text-top;
  width: 10px;
  height: 17px;
  background-image: url("../../assets/images/full-shape.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.fullShapeSquare_1 {
  position: absolute;
  top: 138px;
  left: -1%;
  background-image: url("../../assets/images/brand-transition-desktop.png");
  width: 50px;
  height: 20px;
}
.fullShapeSquare_2 {
  position: absolute;
  top: 480px;
  right: -5px;
  z-index: 2;
}
.fullShapeSquare_3 {
  position: absolute;
  bottom: 91px;
  right: 45%;
}

.signupContent {
  display: inline-block;
  width: 41%;
  padding-left: 6%;
  vertical-align: top;
  position: relative;
  top: 100px;
}

.signupContent > h2 > .title {
  display: inline-block;
  width: 100%;
  height: 72px;
  background-image: url("../../assets/images/csh-logo-horizontal.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.signupContent .textarea {
  display: inline-block;
  width: 100%;
  color: white;
  margin-bottom: 50px;
  font-size: 60px;
  font-family: Cairoli-Now-Condensed;
  line-height: 1;
}

.imageBox {
  position: relative;
  display: inline-block;
  width: 53%;
}
.signupImage {
  position: relative;
  z-index: 2;
  left: 60px;
  top: 20px;
  display: inline-block;
  width: 50%;
  height: 550px;
  background-image: url("../../assets/images/iPhone-12-Pro.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.imageFrame {
  position: absolute;
  z-index: 1;
  top: -50px;
  left: 142px;
  display: inline-block;
  width: 50%;
  height: 414px;
  background-image: url("../../assets/images/frame-desktop.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.mobileTransitionIcon {
  display: none;
}
/* .signupBtn {
  display: inline-block;
  width: 182px;
  height: 35px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  background-color: #ffbc0f;
}
.signupBtn:hover {
  background-color: #ffcf56;
} */

.signupBtnPart {
  position: relative;
  display: inline-block;
}
/* dowload App part */
.downloadAppBtn {
  position: relative;
  z-index: 10;
  display: inline-block;
  white-space: nowrap;
  white-space: -webkit-nowrap;
  width: 250px;
  height: 35px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  background-color: #fcbc0e;
  border: none;
  outline: none;
}
.downloadAppBtn:hover {
  background-color: #fcbd0ea8;
}
.downloadAppBtn:active {
  transform: scaleX(99%);
}

.btnFrame {
  display: inline-block;
  width: 260px;
  height: 30px;
  position: absolute;
  top: -5px;
  left: -5px;
}
.btnFrame > span {
  display: inline-block;
  width: 25%;
  height: 24%;
  box-sizing: border-box;
}
.topLeftLine,
.bottomLeftLine {
  margin-right: 50%;
}
.topLeftLine,
.topRightLine {
  margin-bottom: 8%;
}
.topLeftLine {
  border-top: 1px solid #777;
  border-left: 1px solid #777;
}
.topRightLine {
  border-top: 1px solid #777;
  border-right: 1px solid #777;
}
.bottomLeftLine {
  border-bottom: 1px solid #777;
  border-left: 1px solid #777;
}
.bottomRightLine {
  border-bottom: 1px solid #777;
  border-right: 1px solid #777;
}
.btnText {
  position: relative;
  bottom: 1px;
  color: black;
  text-decoration: none;
  outline: none;
}
.appleIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 3px;
  vertical-align: bottom;
  background-image: url("../../assets/images/icon-apple.svg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
  .signupPart {
    padding-bottom: 20px;
  }
  .signupContent > h2 {
    margin: 0;
  }
  .signupContent .textarea {
    font-size: 50px;
  }
  .fullShapeSquare_1 {
    top: 102px;
    width: 40px;
  }
  .fullShapeSquare_2 {
    top: 480px;
  }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .fullShapeSquare_1,
  .fullShapeSquare_2,
  .fullShapeSquare_3 {
    display: none;
  }
  .signupPart {
    display: inline-block;
    height: 100%;
    padding-top: 30px;
    padding-bottom: unset;
    box-sizing: border-box;
  }
  .signupContent {
    position: relative;
    top: 0;
    z-index: 20;
    width: 100%;
    padding-top: unset;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
    box-sizing: border-box;
  }
  .signupContent > h2 > .title {
    width: 250px;
    height: 46px;
  }
  .signupContent .textarea {
    text-align: center;
    font-size: 40px;
    margin-top: 500px;
    margin-bottom: 30px;
  }
  .imageBox {
    position: absolute;
    top: 13%;
    left: 0;
    width: 100%;
  }
  .signupImage {
    left: unset;
    top: unset;
    width: 100%;
    height: 730px;
    background-position: top;
    background-image: url("../../assets/images/iPhone-12-Pro-mobile.png");
  }
  .imageFrame {
    top: -65px;
    left: 87px;
    width: 100%;
    height: 339px;
  }
  .mobileTransitionIcon {
    display: block;
    width: 15px;
    height: 61px;
    margin: 0 auto;
    margin-top: 30px;
    background-image: url("../../assets/images/brand-transition-mobile.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .signupBtnPart {
    padding-bottom: 50px;
  }
  .disabledSignupBtn {
    margin-top: unset;
  }
}
