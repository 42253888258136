.App {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #1b1b1b;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
  .contentBox {
    display: inline-block;
    width: 100%;
    max-width: unset;
  }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .App {
    overflow: hidden;
  }
}
