.feedbackBox {
  padding: 50px 60px;
}

.feedbackCloseBtn {
  position: absolute;
  top: 0;
  right: 60px;
  font-style: unset;
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.feedbackCloseBtn:hover {
  color: unset;
}

.feedbackContent > h2 {
  color: #fff;
  font-size: 30px;
  margin-block-end: 0.50em;
}
.feedbackContent > form {
  margin-top: 35px;
}
.feedbackContent > form > h3 {
  color: #fff;
}
.feedbackContent > form > h3 > span {
  font-weight: lighter;
  color: #777;
}
.feedbackContent > form > input[type=text],
.feedbackContent > form > .name > input[type=text],
.feedbackContent > form > #feedbackInput {
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: black;
  padding: 15px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
}
.feedbackContent > form > #emailInput {
  width: 100%;
}
.feedbackContent > form > .name {
  width: 100%;
}
.feedbackContent > form > .name > #firstNameInput,
.feedbackContent > form > .name > #lastNameInput {
  width: 49%;
}
.feedbackContent > form > .name > #firstNameInput {
  float: left;
}
.feedbackContent > form > .name > #lastNameInput {
  float: right;
}

.feedbackContent > form > #feedbackInput {
  width: 100%;
  height: 150px;
  resize: none;
}
.feedbackContent > form > input[type=text]:focus,
.feedbackContent > form > .name > input[type=text]:focus,
.feedbackContent > form > #feedbackInput:focus {
  background-color: rgb(22, 20, 20);
  border-radius: 3px;
}

.feedbackButtonContainer {
  display: block;
  margin: auto;
  text-align: center;
}
.feedbackButton {
  display: inline-block;
  width: 182px;
  height: 35px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  background-color: #ffbc0f;
}
.feedbackButton:hover {
  background-color: #ffcf56;
}
.feedbackButton:disabled {
  background-color: grey;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
  
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .feedbackBox {
    padding: 30px 20px 100px;
  }
  .feedbackCloseBtn {
    right: 15px;
  }
}
